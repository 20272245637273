.PopoverButton {
    display: flex;
    background-color: #f1f1f1;
    border-radius: 100px;
    padding: 0.25rem 0.5rem;
    color: #323232;

    .container {
        display: flex;
        align-items: center;
        gap: 0.125rem;
        margin-top: -1px;

        p {
            margin: 0;
            font-size: 13px;
            font-weight: 400;
        }
    }

    &::after {
        width: 1rem;
        height: 1rem;
        content: url(../../../../../../../../assets/icons/arrow-down.svg);
        margin-left: 0.5rem;
        margin-bottom: 1px;
        z-index: 1;
    }

    &.active {
        &::after {
            background-color: #e6e6e6;
            border-radius: 0.5rem;
        }
    }

    &:not(.active) {
        .popover {
            display: none;
        }
    }

    .popover {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap-reverse;
        position: absolute;
        transform: translate(-9px, -3px);
        width: 70px;

        .popover-elements {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            background-color: #f2f2f2;
            border-radius: 1rem 0 1rem 1rem;
            padding: 0.75rem 0.5rem;

            p {
                font-size: 12px;
                font-weight: 400;
                text-align: center;
                margin: 0;
            }
        }

        &::before {
            display: block;
            content: "";
            width: 1.5rem;
            height: 1.5rem;
            background-color: #f2f2f2;
            border-radius: 100px 100px 0 0;
        }
    }
}
