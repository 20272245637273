.header-icon {
  /* width: 375px; */
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  padding-bottom: 15px;
  /*dh*/
  border-bottom: 0.5px solid lightgray;
  /* 상단에 실선 추가 */

}

.logo-img {
  width: 36px;
  height: 37px;
  margin-left: 16px;
}

.right-header img {
  margin-right: 16px;
  cursor: pointer;
}



/* 추천레시피 */
/* .title {
  clear: both;
  width: 100%;
  margin-top: 30px;
  height: 200px;
  padding-left: 12px;
  padding-right: 12px;
} */

.title {
  width: 100%;
  margin: 0 auto;
  margin-top: 30px;
}

.recipe-name {
  color: #1A1A1A;
  font-size: 18px;
  font-weight: 500;
  font-family: "Pretendard";
}

.recipe-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  margin-left: 5px;
  padding-left: 5px;
  padding-right: 5px;
}


.selling-product-text{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  margin-left: 5px;
  padding-left: 5px;
  padding-right: 5px;

}

.more {
  display: flex;
  font-size: 14px;
  font-weight: 500;
  color: #333333;
  cursor: pointer;
  border: none;

}

.a {
  text-decoration: none;
}

.more img {
  margin-left: 8px;
  margin-right: 8px;
}


.photoStyle span {
  background-color: white;
  border-radius: 4px;
  padding: 4px 10px;
  opacity: 0.8;
  max-width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.photoStyle img {
  position: relative;
  bottom: 88px;
  left: 40px;
  cursor: pointer;
}

/* 핫한 식품 */

.gredient {
  margin-top: 50px;
}

.saleGrd {
  display: flex;
  margin-top: 10px;
  padding: 16px 0;
  cursor: pointer;
}

.grd-img {
  width: 92px;
  height: 92px;
  flex-shrink: 0;
  border-radius: 4px;
  margin-right: 16px;
  background-color: #d9d9d9;
}

.sell-title {
  display: flex;
  align-items: center;
  /*아이콘 수평정렬*/
  font-size: 16px;
  font-weight: 600;
  color: #333;
}

.sell-title img {
  margin-left: 25px;
}

.seller {
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin-top: 13px;
  font-size: 14px;
}

.location {
  display: flex;
  align-items: center;
  color: #666;
  font-size: 14px;
}

.tags {
  display: flex;
}

.tags div {
  margin-right: 12px;
  /* 해시태그 사이의 간격 조절 */
  padding: 4px 10px;
  /* 해시태그의 내부 여백 설정 */
  font-size: 14px;
  color: #404040;
  background-color: #efefef;
  border-radius: 4px;
  cursor: pointer;
}

.tags div:last-child {
  margin-right: 0;
  /* 마지막 해시태그의 오른쪽 마진 제거 */
}



.link-style {
  text-decoration: none;
}


.sale-gredient-container {
  margin-bottom: 100px;
}

.plus {
  position: fixed;
  bottom: 80px;
  /* 원하는 위치로 조정 */
  right: 20px;
  /* 원하는 위치로 조정 */
  z-index: 9999;
  /* 다른 요소 위에 표시되도록 z-index 설정 */
  padding: 12px;
  border-radius: 100px;
  background: #ffd31a;
  cursor: pointer;
}

.dropup {
  position: fixed;
  right: 0;
  bottom: 0;
  margin-bottom: 140px;
  margin-right: 15px;
}

.link-style {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 8px;
  font-size: 14px;
  color: #333;
  border: 1px solid #333;
  border-radius: 100px;
  background-color: #fff;
  text-decoration: none;
}

.mainpage-container {
  margin-top: 30px;
  overflow-y: auto;
}

.more-product-link-style {
  text-decoration: none;
}

