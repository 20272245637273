.search-address-container {
    background-color: #f5f5f5;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;

}

.search-address-input-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* 수직 중앙 정렬 */
    align-items: center;
    /* 수평 중앙 정렬 */

}


.search-address-input {

    display: flex;
    width: 80%;
    padding: 12px 16px;
    align-items: center;
    border-radius: 10px;
    background: #eaeaea;
    border: none;

}

.search-address-button-container {
    margin-top: 44px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.search-address-button {
    width: 100%;
    display: inline-flex;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    border: 1px solid #666;
    background-color: #F9F9F9;

}