.search-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.search-header {
  display: flex;
  align-items: center;
  width: 93%;
  margin-bottom: 40px;
  margin-top: 20px;

}

.input-container {
  position: relative;
  width: 100%;
  margin-left: 16px;
  /* 입력란과 아이콘이 한 줄에 나타나도록 함 */
}

.product-search {
  display: flex;
  width: 100%;
  padding: 16px;
  border: none;
  border-radius: 100px;
  background: #F1F1F1;
  box-sizing: border-box;
  /* 아이콘 너비 + 여백 만큼의 오른쪽 여백 추가 */
}

.search-icon {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  /* 세로 중앙 정렬 */
}

.search-back-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

