div {
  white-space: pre-wrap;
}

.product-detail-container {
  background-color: #fff;
  /* 회색 바탕화면 색상 */
  height: 100vh;
  padding: 20px;
  padding-top: 30px;
  max-height: calc(100vh - 60px);
  /* 페이지 전체 높이에서 '구매하기' 버튼의 높이를 뺀 값으로 최대 높이 설정 */
  overflow-y: auto;
  /* 스크롤이 필요한 경우 스크롤 활성화 */
  margin-bottom: 60px;
}

.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* 수직 정렬 */
  justify-content: center;
  /* 수평 정렬 */
  margin-top: 30px;
}

.product-images {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
}

.product-header {
  max-width: 100%;
  height: 10px;
  margin: 0px;
  text-align: center;
  display: flex;
  align-items: center;
}

.back-button {
  margin-top: 4px;
  border: none;
  background-color: transparent;
}

.price-date-container {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  gap: 12px;
}

.product-title {
  font-family: Pretendard;
  font-weight: 500;
  font-size: 16px;
  line-height: 19.09px;
  letter-spacing: -1%;
}

.product-price {
  font-family: Pretendard;
  font-weight: 600;
  font-size: 20px;
  line-height: 23.87px;
  letter-spacing: 0%;
}

.product-info-container {
  display: flex;
  gap: 8px;
  font-family: Pretendard;
  font-weight: 400;
  font-size: 13px;
  line-height: 15.51px;
  letter-spacing: 0%;
  color: #a6a6a6;
}

.profile-img {
  width: 32px;
  height: 32px;
  background-color: #eaeaea;
  border-radius: 50%;
}

.product-seller {
  display: flex;
  gap: 8px;
  margin-top: 24px;
  align-items: center;
}

.seller-name {
  color: #333;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.16px;
}

.divide-line {
  width: 100%;
  height: 1px;
  background-color: #eaeaea;
  margin-top: 24px;
}

.product-address-container {
  font-family: Pretendard;
  font-weight: 500;
  font-size: 16px;
  line-height: 23.04px;
  letter-spacing: 0%;
  margin-top: 32px;
}

.product-address {
  display: flex;
  gap: 8px;
  display: flex;
  align-items: center;
  font-family: Pretendard;
  font-weight: 500;
  font-size: 14px;
  line-height: 16.71px;
  letter-spacing: -1%;
  color: #323232;
}

.address-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}

.map-button {
  font-family: Pretendard;
  font-weight: 500;
  font-size: 13px;
  line-height: 15.51px;
  letter-spacing: 0%;
  text-align: center;
  color: #a6a6a6;
}

.reproduct-link {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.link-container {
  margin-bottom: 12px;
  display: flex;
  padding: 12px 12px;
  border-radius: 8px;
  border: 1px solid #b0b0b0;
}

.ingredient-name {
  font-family: Pretendard;
  font-weight: 500;
  font-size: 14px;
  line-height: 16.71px;
  letter-spacing: -1%;
  color: #707070;
}

.link-item {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.link-url a {
  color: #b0b0b0;
  font-family: Pretendard Light;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: inline-block; /* 인라인 블록으로 설정 */
  max-width: 100%; /* 부모 요소를 넘지 않도록 제한 */
  word-break: break-word; /* 긴 단어(긴 URL) 강제 줄 바꿈 */
  overflow-wrap: break-word; /* 단어가 넘칠 경우 줄바꿈 */
  white-space: normal; /* 기본 줄바꿈 허용 */
}

.reproduct-content {
  color: #333;
  font-family: Pretendard Light;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.14px;
  margin-bottom: 16px;
  margin-top: 30px;
}

.product-likes {
  font-family: Pretendard;
  font-weight: 400;
  font-size: 13px;
  line-height: 15.51px;
  letter-spacing: 0%;
  color: #656565;
  margin-bottom: 32px;
}

.product-people-container {
  font-family: Pretendard;
  font-weight: 500;
  font-size: 16px;
  line-height: 23.04px;
  letter-spacing: 0%;
  margin-top: 32px;
}

.product-people {
  display: flex;
  gap: 8px;
  color: #191919;
  font-family: Pretendard Light;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.14px;
  margin-top: 16px;
}

.product-recipe-header {
  color: #191919;
  font-family: ABeeZee;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.16px;
  margin-bottom: 16px;
  margin-top: 32px;
}

.product-day-container {
  font-family: Pretendard;
  font-weight: 500;
  font-size: 16px;
  line-height: 23.04px;
  letter-spacing: 0%;
  margin-top: 32px;
}

.product-day {
  margin-top: 16px;
  display: flex;
  gap: 8px;
  align-items: center;
}

.close-day {
  font-family: Pretendard;
  font-weight: 500;
  font-size: 14px;
  line-height: 16.71px;
  letter-spacing: -1%;
}

.remaining-day {
  font-family: Pretendard;
  font-weight: 500;
  font-size: 12px;
  line-height: 14.32px;
  letter-spacing: 0%;
  color: #f5651d;
}

.product-button-container {
  display: flex;
  gap: 8px;
  width: 100%;
  font-family: Pretendard;
  font-weight: 500;
  font-size: 15px;
  line-height: 16.71px;
  letter-spacing: 0%;
  text-align: center;
  margin-top: 31.5px;
}

.pick-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  border-radius: 8px;
  padding-top: 12px;
  padding-right: 16px;
  padding-bottom: 12px;
  padding-left: 12px;
  gap: 8px;
  background-color: #f1f1f1;
  height: 30px;
  cursor: pointer;
}

.buy-button {
  display: flex;
  width: 70%;
  border-radius: 8px;
  padding-top: 12px;
  padding-right: 16px;
  padding-bottom: 12px;
  padding-left: 16px;
  gap: 8px;
  background-color: #ffdc25;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.buy-product-price {
  font-family: Pretendard;
  font-weight: 700;
  font-size: 15px;
  line-height: 16.71px;
  letter-spacing: 0%;
  text-align: center;
}
