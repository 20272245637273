.UserChat {
    display: flex;
    max-width: 100%;

    .profileImage {
        width: 2rem;
        height: 2rem;
        border-radius: 2rem;
        background-color: #eaeaea;
        margin-right: 0.5rem;
    }

    .nameAndMessage {
        display: flex;
        flex-direction: column;
        gap: 1px;

        .name {
            font-size: 0.875rem;
            line-height: 1.0625rem;
            font-weight: 500;
            padding: 0.5rem 0;
            margin: 0;
        }

        .messageAndTime {
            display: flex;
            align-items: flex-end;
            gap: 0.25rem;

            .messageTime {
                display: flex;
                align-items: flex-end;
                font-size: 0.75rem;
                font-weight: 400;
                line-height: 0.875rem;
                color: #a6a6a6;
                margin: 0;
                padding: 0;
            }
        }
    }
}

.self {
    justify-content: end;
}
