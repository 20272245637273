.MessageCard {
    width: fit-content;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.0625rem;
    color: #272727;
    background-color: #f1f1f1;
    padding: 0.75rem 1rem;
    border-radius: 0 0.5rem 0.5rem 0.5rem;
    word-break: break-all;
}

.self {
    background-color: #ffdc25;
    border-radius: 0.5rem 0 0.5rem 0.5rem;
}
