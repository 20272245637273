.Button {
    font-weight: 500;
    color: #191919;
    text-align: center;
    border-radius: 0.5rem;
    padding: 0.75rem;

    &--full {
        width: -webkit-fill-available;
    }

    &--md {
        font-size: 0.9375rem;
        line-height: 1.125rem;
    }
}

.white {
    background-color: white;
    border: 1px solid #d9d9d9;
}

.yellow {
    background-color: #ffdc25;
}
