
  
  .more-grd-name {
      color: var(--black-10, #191919);
      font-family: 'Pretendard Black'; /* 새로 추가된 폰트 적용 */
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.32px;
      margin-left: 8px;
  }
  


.recipe-ingredient-buttons button {
    margin-right: 8px;
    margin-top: 8px;
    margin-bottom: 8px;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    border: none;
    color: var(--grey-40, #666);

    /* b14 */
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.14px;


}

.page-button-active {
    background: var(--brand_color, #FFDC25);
    color: var(--black-10, #191919);

    /* b14 */
    font-family: Pretendard ExtraBold;
    font-size: 14px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: -0.14px;
}

.more-product-header {
    display: flex;
}

.more-grd-name {
    color: var(--black-10, #191919);

    /* h20 */
    font-family: Pretendard ExtraBold;
    
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.32px;
    margin-left: 8px;


}



.more-gredient {
    margin-top: 16px;
    padding: 16px;
}