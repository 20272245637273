/* 모달 스타일 */
.modal {
  width: 100%;
  flex-shrink: 0;
  position: fixed;
  bottom: 0;
  z-index: 10001;
  background-color: white;
  padding: 14px 18px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}


.modal-address {
  color: #191919;
  font-family: Pretendard;
  font-weight: 500;
  font-size: 20px;
  line-height: 23.87px;
  letter-spacing: -1.6%;
  margin-bottom: 15px;
}

.modal-address-button-container {
  margin-top: 24px;
  margin-left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-address-button {
  display: flex;
  width: 95%;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: none;
  background: var(--brand_color, #ffdc25);
  color: #191919;
  text-align: center;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.16px;
}

.seletctmap-back-button {
  position: absolute;
  top: 28px;
  left: 18px;
  z-index: 10001;
  border: none;
  background-color: transparent;
}
