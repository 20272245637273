.reg-header{
    width: 343px;
    height: 24px;
    margin-top: 54px;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;    
}

.reg-header img{
    margin-right: 90px;
    opacity: 0.5;
}
.reg-header text{
    margin-left: 8px;
}

.reg-body{
    width: 343px;
    height: 670px;
    max-height: 670px;
    overflow-y: auto;

    padding: 0 16px;
}
.reg-body::-webkit-scrollbar {
    width: 0; 
}

.reg-body input::placeholder{
    color: #BCBCBC;
}

.title-reg{
    font-size: 14px;
    font-weight: 600;
    margin-top: 24px;
}

.input-title{
    width: 343px;
    padding: 8px;
    margin-top: 8px;
    border: none;
    border-bottom: 1px solid #D9D9D9;
}

.input-top-img{
    width: 343px;
    height: 96px;
    border-radius: 8px;
    border: 1px solid #D9D9D9;
    margin-top: 8px;
    
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
}

.input-top-img img {
    max-width: 100%;
    cursor: pointer;
}

.plus-btn {
    width: 343px;
    display: flex;
    margin-top: 16px;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    font-size: 16px;
    color: #666;
    border: 1px solid #BCBCBC;
    background-color: #FFF;
    cursor: pointer;
}

.upload-btn {
    width: 343px;
    display: flex;
    margin: 40px 0;
    padding: 12px 16px;

    justify-content: center;
    align-items: center;
    border-radius: 8px;

    font-size: 16px;
    color: #191919;
    border: none;
    background-color: #FFDC25;
    cursor: pointer;
}

input:focus {
    outline: none;
}