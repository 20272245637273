div {
  white-space: pre-wrap;
}

.scrap-back-button-container {
  margin-left: 16px;
  margin-top: 24px;
  margin-bottom: 25px;
  display: flex;
}

.scrap-back-button {
  border: none;
  background-color: transparent;
}

.scrap-back-button:hover {
  cursor: pointer; /* 마우스 커서 변경 */
}

.scrap-container {
  background-color: #fff;
  /* 회색 바탕화면 색상 */
  height: 100vh;

  max-height: calc(100vh);
  /* 페이지 전체 높이에서 '구매하기' 버튼의 높이를 뺀 값으로 최대 높이 설정 */
  overflow-y: auto;

  /* 스크롤이 필요한 경우 스크롤 활성화 */
}

.scrap-profile-container {
  border: #5e5e5e 1px solid;
  margin-top: 13px;
  margin-left: 13px;
  margin-right: 16px;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  position: relative;
  justify-content: center;
}

.scrap-profile-name-satisfaction-container {
  margin-left: 18px;
  margin-top: 8px;
}

.scrap-profile-image {
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--grey-85, #d9d9d9);
  position: relative;
}

.my-satisfaction-container {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.my-satisfaction-num {
  color: var(--black-10, #191919);
  text-align: left;

  /* b14 */
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.14px;
}

.my-satisfaction-bar {
  margin-left: 8px;
  margin-top: 5px;
  margin-bottom: 5px;

  width: 155px;
  height: 12px;
  background-color: #d9d9d9;
  border-radius: 5px;
  overflow: hidden;
}

.my-satisfaction-fill {
  height: 100%;
  background-color: #ffdc25;
}

.scrap-profile-name {
  color: var(--black-10, #191919);
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.32px;
}

.scrap-option-container {
  margin-top: 30px;
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
  height: 40px;
  padding: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  background: var(--grey-95, #f2f2f2);
}

.option-buttons button {
  transition: background-color 0.3s, color 0.3s; /* 전환 부드럽게 */
  cursor: pointer; /* 마우스 커서 변경 */
}

.option-buttons button:hover {
  background-color: rgba(0, 0, 0, 0.1); /* 호버 시 배경 색상 변경 */
}

.profile-modify-button {
  border-radius: 100px;
  background: #fff;
  display: inline-flex;
  padding: 7px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  z-index: 100;
  position: absolute;
  right: 10px;
  top: 10px;
  /* margin-bottom: 10px;
  margin-right: 10px; */
}

.profile-modify-button:hover {
  cursor: pointer; /* 마우스 커서 변경 */
}

/* active 상태일 때, hover해도 색상 그대로 */
.option-buttons button.active:hover {
  background-color: #ffdc25; /* 배경 색상 변경 없음 */
}

.wishlist-buttons {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  margin-left: 16px;
}

.wishlist-button {
  display: flex;
  padding: 4px 14px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 100px;
  border: 1px solid var(--grey-85, #d9d9d9);
  color: #bdbdbd;
  text-align: center;

  /* b14 */
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.14px;
}

.wishlist-button.active {
  background: var(--black-10, #191919);
  color: #fff; /* 텍스트 색상을 흰색으로 설정 */
}

.scrap-scroll-container {
  display: flex;
  justify-content: center;
}

.scrap-scroll {
  margin-bottom: 80px;
  width: 100%;
}
