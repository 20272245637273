.sale-product {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  border-bottom: 0.5px solid lightgray;
  cursor: pointer;
}

.sale-product-container{
  padding: 16px;
}

.sale-product:hover {
  cursor: pointer; /* 마우스 커서 변경 */
}

.product-details {
  display: flex;
}

.product-image {
  width: 114px;
  height: 114px;
  margin-right: 20px;
  border-radius: 5px;
}

.product-details h2 {
  margin: 0;
}

.product-date{
  display: flex;
  margin-top: 12px;
  gap: 8px;
font-family: Pretendard;
font-size: 13px;
font-weight: 400;
line-height: 15.51px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #707070;
}

.product-due-date{
  color: #E0392D;
  font-weight: 600;
}

.product-item {
  margin-top: 12px;
  margin-right: 5px;
  padding: 4px 10px;
  justify-content: center;
  display: inline-block;
  align-items: center;
  gap: 7px;
  border-radius: 8px;
  background: var(--grey-95, #f2f2f2);
  color: var(--grey-40, #666);
  font-family: ABeeZee;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.14px;
}

.sale-product-title {
  color: var(--black-10, #191919);
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.15px;
}

.sale-product-price {
  color: var(--black-10, #191919);
  font-family: Pretendard Bold;
  font-size: 20px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  margin-top: 8px;
}

.sale-product-satisfaction-container {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
}

.sale-product-satisfaction-bar {
  margin-top: 5px;
  width: 96px;
  /* 바의 너비 설정 */
  height: 8px;
  /* 바의 높이 설정 */
  background-color: #d9d9d9;
  /* 바의 배경색 설정 */
  border-radius: 5px;
  /* 바의 모서리를 둥글게 만듭니다. */
  overflow: hidden;
  /* 만족도를 나타내는 바의 내용이 바깥으로 넘치지 않도록 합니다. */
}

.sale-product-satisfaction-fill {
  height: 100%;
  background-color: #f0d135;
}

.sale-product-satisfaction {
  text-align: right;
  color: #262626;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.14px;
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  position: absolute;
  right: 0;
}

.sale-product-user-name {
  color: var(--black-20, #333);
  font-family: Pretendard Light;
  font-size: 14px;
  font-style: normal;
  font-weight: 00;
  line-height: normal;
  letter-spacing: -0.14px;
}

.sale-product-satisfaction-num {
  color: var(--black-20, #333);
  text-align: center;
  font-family: ABeeZee;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.12px;
}

.sale-product-text-pick {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.sale-product-pick-button{
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.date-people {
  display: flex;
  margin-bottom: 8px;
}

.due-date {
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
  background: var(--brand_color, #ffdc25);
  color: #cd8c0b;

  /* b12_r */
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}



