.recipePhoto {
    margin: auto;
    margin-top: 24px;
    display: flex;
    max-width: 90%;
    overflow-x: auto;
    overflow-y: hidden;
    gap: 16px;
    padding-bottom: 10px;
  }

  .photoStyle {
    margin-top: 8px;
    color: #191919;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 500;
    line-height: 19.6px;
    letter-spacing: -0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

  }

  .rec-photo {
    width: 180px;
    height: 144px;
    border-radius: 16px;
    flex-shrink: 0;
    cursor: pointer;
  }
  