.Footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    border-top: 1px solid #eaeaea;
    padding: 0.75rem 1rem;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    background-color: white;
}
