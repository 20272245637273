.recently-search {
  width: 100%;
  text-align: left;
  color: var(--black-10, #191919);

  /* h16 */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.16px;
}

.search-delete-button {
  font-size: 14px;
  border: none;
  background-color: transparent;
}

.recently-search-container {
  display: flex;
  flex-direction: column;
  width: 93%;
}

.recently-search-items {
  margin-top: 16px;
  width: 100%;
}

.recently-search-item {
  width: 100%;
  align-items: center;
  display: flex;
  height: 49px;
  justify-content: space-between;
  align-items: center;
  color: var(--black-20, #333);
  border-bottom: 1px solid var(--grey-90, #e6e6e6);

  /* b14 */
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.14px;
}

.search-select-container {
  width: 100%;
  padding-bottom: 60px;
}

.search-select {
  display: flex;
}

.search-option-buttons {
  display: flex;
  margin-left: 16px;
  gap: 8px;
}

.search-option-buttons button {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-top: 8px;
  padding-right: 16px;
  padding-bottom: 8px;
  padding-left: 16px;
  border-radius: 100px;
  border-width: 1px;
  background-color: #ffffff;
  border: 1px solid var(--grey_100, #eaeaea);
  font-family: Pretendard;
  font-weight: 500;
  font-size: 14px;
  line-height: 16.71px;
  letter-spacing: 0%;
  color: #323232;
  cursor: pointer;
}

/* 선택된 버튼에 대한 스타일 */
.search-option-buttons button.active {
  background: #1a1a1a;
  font-weight: bold;
  color: #ffffff;
  font-family: Pretendard;
  font-weight: 400;
  font-size: 14px;
  line-height: 16.71px;
  letter-spacing: -1%;
  text-align: center;
  /* 선택된 버튼을 구별하기 위해 배경색 변경 */
}

.none-search {
  margin-top: 30px;
  text-align: center;
}
