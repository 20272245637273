.proudct-post-inputform {
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 16px;
}

.product-post-title {
    width: 95%;
    color: #333;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.14px;
}

.product-post-input {
    display: flex;
    width: 95%;
    padding: 8px;
    align-items: center;
    gap: 10px;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid #D9D9D9;

}