.Textarea {
    border-radius: 0.5rem;
    border: 1px solid #d9d9d9;
    padding: 0.75rem;

    &--full {
        width: -webkit-fill-available;
    }

    &::placeholder {
        font-family: "Pretendard Variable", Pretendard, -apple-system,
            BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI",
            "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic",
            "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
        color: #989898;
    }
}
