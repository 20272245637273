.product-post-ingredient-container {
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 16px;


}

.product-post-ingredient-text {
    margin-left: 16px;
    margin-bottom: 16px;
    width: 95%;
    color: #333;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.14px;
}

.product-post-ingredient-num {
    display: flex;
    width: 25px;
    height: 25px;
    padding: 4px 4px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 4px;
    background: var(--grey-90, #E6E6E6);
    margin-right: 6px;

}

.product-post-ingredient-num-container {
    display: flex;
    flex-direction: row;
}

.product-post-ingredient-name {
    display: flex;
    padding: 8px;
    align-items: center;
    flex-shrink: 0;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid var(--grey-85, #D9D9D9);
    color: var(--grey-60, #989898);
    width: 84%;
    margin-right: 16px;

    /* b14_r */
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.14px;

}

.product-post-ingredient-url {
    margin-top: 16px;
    margin-left: 39px;
    width: 84%;
    display: flex;
    padding: 8px;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid var(--grey-85, #D9D9D9);
    color: var(--grey-60, #989898);

    /* b14_r */
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.14px;
}

.ingredient-add-button-container {
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 16px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ingredient-add-button {
    display: flex;
    width: 100%;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid var(--grey-75, #BCBCBC);
    background-color: transparent;
}