.ListElement {
    display: flex;
    padding: 1rem;
    gap: 1rem;

    .image {
        width: 3rem;
        height: 3rem;
    }

    .contents {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
        flex-grow: 1;

        p {
            margin: 0;
        }

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .title {
                font-size: 0.875rem;
                color: #191919;
                line-height: 1.0625rem;
                font-weight: 500;
                max-width: calc(100% - 4rem);
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .lastTime {
                color: #a6a6a6;
                font-size: 0.75rem;
                line-height: 0.875rem;
                font-weight: 500;
                max-width: 4rem;
            }
        }

        .footer {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .lastMessage {
                font-size: 0.8125rem;
                line-height: 1.0625rem;
                font-weight: 600;
                color: #191919;
                width: 70vw;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

.NewMessageAlert {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 0.5rem;
    background-color: #f5d21d;
}
