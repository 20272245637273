.Notice {
    background-color: #eaeaea;
    width: fit-content;
    margin: 0 auto;
    color: #272727;
    font-size: 0.8125rem;
    font-weight: 500;
    line-height: 0.9375rem;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
}
